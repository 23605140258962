import firebase from "firebase";

export function initializeFirebase() {
    const config = {
        apiKey: "AIzaSyDoPEamNBsECs6rroBXxsZmjTBkN97mo24",
        authDomain: "ais-proj.firebaseapp.com",
        databaseURL: "https://ais-proj.firebaseio.com",
        projectId: "ais-proj",
        storageBucket: "ais-proj.appspot.com",
        messagingSenderId: "280511809582",
        appId: "1:280511809582:web:769a31ee04c78b0e49b7bf",
        measurementId: "G-YRK99S00ZF"
    };
    firebase.initializeApp(config);
    firebase.analytics()
}
