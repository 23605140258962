import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // display: 'flex',
            flexWrap: 'wrap',
            minWidth: 300,
            width: '100%',
        },
        image: {
            position: 'relative',
            height: 200,
            [theme.breakpoints.down('xs')]: {
                width: '100% !important', // Overrides inline-style
                height: 100,
            },
            '&:hover, &$focusVisible': {
                zIndex: 1,
                '& $imageBackdrop': {
                    opacity: 0.15,
                },
                '& $imageMarked': {
                    opacity: 0,
                },
                '& $imageTitle': {
                    border: '4px solid currentColor',
                },
            },
        },
        focusVisible: {},
        imageButton: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.common.white,
        },
        imageSrc: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: 'cover',
            backgroundPosition: 'center 40%',
        },
        imageBackdrop: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.common.black,
            opacity: 0,
            transition: theme.transitions.create('opacity'),
            
            
        },
        imageTitle: {
            position: 'relative',
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
        },
        imageMarked: {
            height: 3,
            width: 18,
            backgroundColor: theme.palette.common.white,
            position: 'absolute',
            bottom: -2,
            left: 'calc(50% - 9px)',
            transition: theme.transitions.create('opacity'),
        },
        roundoLine:{
            borderRadius: 20,


        },
    }),
);

interface Props {
    onClick: VoidFunction;
    imageSrc: string;
    title: string;
    width: number | string;
}

// onClick = {() => update(0)} title = ' ' imageSrc = { soccer } width = '100%

export default function ComplexButton({ onClick, imageSrc, title, width }: Props) {
    const classes = useStyles();

    return (
    <CardActionArea className= {classes.roundoLine}>
            <Box fontSize="h4.fontSize">
                {title}
            </Box>
        <CardContent>
        <div className={classes.root}>

            <ButtonBase
                
                focusRipple
                key={title}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                onClick={onClick}
                style={{
                    width: width,
                }}
             
            >
                <span
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${imageSrc})`,
                    }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                    
                    
                    {/* <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        className={classes.imageTitle}
                    >
                        {title}
                            </Typography> */}
                        <span className={classes.imageMarked} />
                  
                </span>
            </ButtonBase>
        </div>
         
        </CardContent>
        </CardActionArea>
   
    );
}