import React, { FC, useState } from "react";
import { Container } from "@material-ui/core";
import BubbleChart from "../components/bubbleChart";
import { Similarity, SimilarityWithLinks } from '../types'
import { makeStyles, Theme, createStyles,Grid } from "@material-ui/core";
import AnchoredList from "../components/results/AnchoredList";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const BIAS = 100


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            justifyContent: "center",
        },
        circleList: {
            width: '100%',
            margin: '0 auto',
            justifyContent:"center",
            fontFamily: 'Arial',
        },
        header: {
            display: "flex",
            justifyContent: "center",
            


        }
    }),
);
interface Props {
    similarity: Similarity[];

}

interface ModalProps {
    open: boolean;
    handleClose: VoidFunction;
    circleInfo: SimilarityWithLinks;
}

interface circleProps{
    circleInfo: SimilarityWithLinks;
}
function TransitionsModal({ open, handleClose, circleInfo }: ModalProps) {
    const classes = useStyles();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{circleInfo.name}</h2>
                        <p id="transition-modal-description">
                            Twitter: <a href={circleInfo.twitterURL}>{circleInfo.twitterURL}</a><br />
                            Instagram: <a href={circleInfo.instagramURL}>{circleInfo.instagramURL}</a>
                        </p>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
const ResultPage: FC<Props> = ({ similarity }) => {
    const initialCircle: SimilarityWithLinks = { name: "", similarity: 0 }
    const [open, setOpen] = React.useState(false);
    const [selectedCircle, setCircle] = React.useState<Similarity>(initialCircle)
    const handleOpen = (label: string) => {
        // 部活の情報を取ってきてTransitionsModalに渡す
        const selected = similarity.find((circle) => circle.name === label) || initialCircle
        setCircle(selected)
        setOpen(true);
    };
    const handleClose = () => {
        setCircle(initialCircle)
        setOpen(false);
    };
    const styles = useStyles()
    const weight = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]
    const renderData = similarity
            .sort((a, b) => b.similarity - a.similarity)
            .map(s => ({ label: s.name, value: ~~(s.similarity * BIAS) }))
    // const renderData = similarity.map(s => ({ label: s.name, value: ~~(s.similarity * BIAS) }))
    const listItems = similarity
        .sort((a, b) => b.similarity - a.similarity)
        .map(
            (s:SimilarityWithLinks, index) => ({
                id: s.name,
                name: `${index + 1}位 ${s.name}`,
                twitterURL: s.twitterURL,
                instagramURL: s.instagramURL,
                similarity: s.similarity
            })
        )
    return (
        <Container >
            
            
            <h1>出力結果</h1>

            <BubbleChart
                graph={{
                    zoom: 1,
                }}
                width={800}
                height={800}
                showLegend={false}
                valueFont={{
                    family: 'Arial',
                    size: 12,
                    color: '#fff',
                    weight: 'bold',
                }}
                labelFont={{
                    family: 'Arial',
                    size: 16,
                    color: '#fff',
                    weight: 'bold',
                }}
                data={renderData}
                bubbleClickFun={handleOpen}
            />
            <TransitionsModal
                open={open}
                handleClose={handleClose}
                circleInfo={selectedCircle}
            />
            
           
            <Grid item xs={12} md={8} >
             <div >
                
                <AnchoredList items={listItems} onClick={handleOpen}  className={styles.circleList} />


            </div>
            </Grid>

            
            
        </Container>

    )
}

export default ResultPage
