import axiosBase from 'axios';

// localhost:3000 -> localhost:3000
// -> localhost:80
// aixos.get('/api/')

const axios = axiosBase.create({
    baseURL: 'https://circh.si.aoyama.ac.jp/'
})

export default axios