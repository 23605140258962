import {Box, makeStyles} from "@material-ui/core";
import React, {FC} from "react";

const useStyles = makeStyles({
    wrapper: {
        padding: 15,
        borderColor: "#3CBF83",
        borderWidth: "0 12px 0 12px",
        borderStyle: "solid",
        textAlign: 'center'
    }
})
export const Footer: FC = () =>  {
    const classes = useStyles()
    return (
        <Box className={classes.wrapper}>
            All Rights Reserved.
        </Box>
    )
}

export default Footer
