//
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import React, {FC, useState} from "react";
import "./App.css";
import {Similarity, SimilarityWithLinks} from "./types/index"
import TopMain from "./pages/topPage";
import TwoSelection from "./pages/TwoSelection";
import Header from "./components/header";
import Result from './pages/resultPage'
import Footer from "./components/footer";
import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";
import {Paper} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        background: {
            paper: "rgb(250, 255, 254, 1)",
            default: "rgb(250, 255, 254, 1)"
        },
        primary: {
            main: "#3CBF83"

        }
    }
})

function App() {
    const [similarity, setSimilarity] = useState<(SimilarityWithLinks[] | null)>(null);
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Header/>
                <Router>
                    <Switch>
                        <Route exact path="/">

                            <TopMain/>
                        </Route>
                        <Route path="/pagetw"
                               render={(props) => <TwoSelection setSelection={setSimilarity} {...props} />}/>

                        <Route path="/result">

                            {!!similarity ? (<Result similarity={similarity}/>) : (<Redirect to='/pagetw'/>)}
                        </Route>
                    </Switch>
                </Router>
                <Footer/>
            </div>
        </ThemeProvider>
    );
}

export default App;

//よくあるのは、App.tsxで一つstateを作成して子コンポーネントに渡すというやり方かなと思います。 （編集済み）
//d3の開発
//値の保持
//stateをどのように作ればいいか
//Resultコンポーネントの作成
