import React, { FC, HTMLAttributes, } from 'react'
import { ListItem, List, ListItemText, Box, Card, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { SimilarityWithLinks } from '../../types'

const RANKING_DECORATION_ARRAY: string[] = ["🥇", "🥈", "🥉"]

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        justifyContent:"center",
        display:"flex"
    },
    media: {
        height: 140,
        
    },
    backGround: {
        backgroundColor: "#b3e5fc",
        justifyContent: "center",
        padding:"5px",

    },

    twitter:{
        marginTop:15,
        display: "flex",
        left: 0,

    },
    similarityPosition:{
        position:"absolute",
        display:"flex",
        right:0,

    },
    instagram:{
        marginTop:10,
        left:0,
    
    },

});

interface ListItemProps extends SimilarityWithLinks {
    id: string;
}
interface ListProps {
    items: ListItemProps[];
    className?: string;
    onClick: (label: string) => void
}

const AnchoredItem: FC<ListItemProps & { index: number; onClick: (label: string) => void; }> = ({ id, name, index, twitterURL,instagramURL,similarity, onClick }) => {

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.media} onClick={() => onClick(id)}>
                <Grid container className={classes.backGround}>
                    <Typography>
                        <ListItemText>{name}</ListItemText>
                    </Typography>
                </Grid>
                <Grid container>
                    <Box fontSize="h6.fontSize">
                        <Grid className ={classes.similarityPosition}>
                        マッチ度{~~(similarity * 100)}%  <br></br>

                        </Grid>
                    </Box>
                       <Grid>
                    
                   
                    <Typography className = {classes.twitter}>
                        
                        Twitter:{twitterURL}
                        

                        
                    </Typography>
                    <Typography className={classes.twitter}>

                        


                        Instagram:{instagramURL}
                    </Typography>
                    </Grid>
                    
                     
                    
                </Grid>
            </CardActionArea>
        </Card>
    )
}


const AnchoredList: FC<ListProps> = ({ items, onClick, ...props }) => {
    /*
        ID付きのリスト
        アンカーリンクから飛んでこれる
    */
    return (
        <Box {...props}>

            {items.map((item, index) => (
                <AnchoredItem {...item} index={index} onClick={onClick} />
            ))}

        </Box>
    )
}

export default AnchoredList
