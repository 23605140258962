import React, {FC, useState, Dispatch} from "react";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    Container,
    Grid,
    Box,
    Typography,
    ButtonBase
} from "@material-ui/core";
import TopPageImage from "../assets/images/TopPageWithoutText.png";
import DescriptionBackground from '../assets/images/description-background.png'

const useStyles = makeStyles({
    heroWrapper: {
        height: 300,
        padding: 40,
        backgroundColor: "rgb(0,163,175)",
        position: "relative",
        overflow: 'hidden',
        zIndex: 0,
        "&::before": {
            content: '""',
            position:  'absolute',
            opacity: 0.5,
            top: -5,
            bottom: -5,
            left: -5,
            right: -5,
            backgroundImage: `url(${TopPageImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            background: 'inherit',
            filter: 'blur(3px)',
            zIndex:-1
        }
    },
    heroText: {
        color: 'white',
        textAlign: 'center'
    },
    heroGrids: {
        margin: '40px auto'
    },
    gotoButton: {
        backgroundColor: "white",
        border: '2px solid rgba(70,70,70, 0.5)',
        borderRadius: 40,
        cursor: 'pointer',
        height: 50,
        padding: '20px 40px',
        // boxShadow: "1px 1px 1px 0 rgba(0, 0, 0, 0.5)",
    },
    gotoButtonText: {
        color: '#3CBF83',
    },
    descriptionWrapper: {
        backgroundImage: `url(${DescriptionBackground})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        padding: '60px 20px'
    }
});

const GotoSelectionButton: FC = () => {
    const classes = useStyles()
    const history = useHistory();
    return (
        <ButtonBase onClick={() => history.push('/pagetw')} className={classes.gotoButton}>
            <Typography variant='body1' component="h1" className={classes.gotoButtonText}>
                診断する
            </Typography>
        </ButtonBase>
    )
}


const Hero: FC = () => {
    const classes = useStyles()
    return (
        <Box display='flex' flexDirection='column' justifyContent="center" className={classes.heroWrapper}>
            <Grid container justify='center' className={classes.heroGrids}>
                <Grid item>
                    <Typography variant='h3' className={classes.heroText} component="h1">
                        サークル選びに革命を
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify='center' className={classes.heroGrids}>
                <Grid item xs={8} md={4}>
                    <GotoSelectionButton/>
                </Grid>
            </Grid>
        </Box>
    )
}

//to do大きさの調整
const TopMain: FC = () => {
    const classes = useStyles();

    return (
        <div>
            <Hero/>
            <Box className={classes.descriptionWrapper}>
                <Grid container justify='center'>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="textSecondary" component="h2">
                            Circhとは何か？
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify='center'>
                    <Grid item xs={12} md={8}>
                        <Typography gutterBottom variant="body1">
                            本学には200を超える部活動やサークル・愛好会が存在し、活動しています。<br/>
                            Circhではいくつかの質問に回答することで皆さんにあったサークルを提案するサービスです。<br/>
                            このサービスを通じで皆さんのサークル選びのサポートができればと思います。<br/>
                            皆さんの学生生活に本サービスが活かされることを願っております。
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{padding: 20}}>
                <Grid justify='center'>
                    <GotoSelectionButton />
                </Grid>
            </Box>
        </div>
    );
};

export default TopMain;
