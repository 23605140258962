import React, { FC } from "react";
import { Button, Container, Grid, Box, Typography, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme: Theme) => createStyles({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginBottom: 20,
    fontFamily: "italic",
    width: "100%",
  },
  headerText: {fontFamily: "'Allura', cursive", fontWeight: 'bold', color: '#707070'}
}));

const Header: FC = () => {
  const styles = useStyle();
  return (
    <Grid  className={styles.header}>
      <Typography variant="h2" className={styles.headerText}>Circh</Typography>
    </Grid>
  );
};

export default Header;
