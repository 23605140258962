import React, { FC, useState, Dispatch } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import WrapTextIcon from "@material-ui/icons/WrapText";
import "./twoselection.css";
import { makeStyles } from "@material-ui/styles";
import { Similarity } from "../types";
import {
  LinearProgress,
  Container,
  Grid,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ComplexButton from "../components/complexButton/complexButton";
import axios from "../axios/axios";

interface Selection {
  id: string;
  value: string;
  text: string;
  img: string;
}

interface SelectionCollection {
  question: string;
  selection: Selection[];
}

interface Props extends RouteComponentProps {
  setSelection: Dispatch<Similarity[]>;
}

const useStyle = makeStyles({
  choices: {
    display: "flex",
    justifyContent: "center",
  },
  choice: {
    textAlign: "center",
    margin: 8,
    padding: 4,
    border: "solid",
    borderColor: "black",
    borderWidth: 1,
  },
  questions: {
    marginTop: 5,
    justifyContent: "center",
    position: "relative",
  },
  topQuestion: {
    marginTop: 50,
    textAlign: "center",
    margin: 8,
    padding: 4,
    border: "solid",
    borderColor: "black",
    borderWidth: 1,
  },
  overlay: {
    zIndex: 100,
    color: "#fff",
  },
  progressBar: {
    borderRadius: 5,
    height: 40,
  },
  backButton: {
    marginTop: 100,
  },
  wholeDesign: {
    marginTop: 50,
  },
  button: {
    color: "primary.main",
    borderRadius: 5,
  },
  outline: {
    backgroundColor: "white",
  },
  divColor: {
    backgroundColor: "rgb(250, 255, 254, 1)",
    padding: 15,
  },
  responsive: {
    margin: "auto",
    justify: "center",
    width: "100%",
  },
});

const TwoSelection: FC<Props> = ({ setSelection, history }) => {
  const [pageReady, setPageReady] = useState(false);
  const [value, setValue] = useState<(number | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState<SelectionCollection[]>([]);
  const styles = useStyle();
  React.useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get("/api/question");
      setSelections(res.data);
      setPageReady(true);
    };
    fetchData();
  }, []);

  async function update(selected: number | null) {
    if (selections.length === value.length + 1) {
      setLoading(true);
      await fetchSimilarity([...value, selected]);
      setLoading(false);
      history.push("/result");
    } else {
      setValue([...value, selected]);
    }
  }

  async function fetchSimilarity(selected: (number | null)[]) {
    const response = await axios.post("/api/similarity", {
      selected_question: selected,
      question_data: selections,
    });

    const circle_similarities = response.data.result;
    setSelection(
      circle_similarities.map((item: any) => ({
        ...item,
        twitterURL: item.twitter_url,
        instagramURL: item.instagram_url,
      }))
    );
  }

  function prev() {
    value.pop();
    setValue([...value]);
  }

  if (!pageReady) {
    return (
      <Container>
        <Backdrop open={true} className={styles.overlay}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }

  return (
    <Grid item xs={12} justify="center" className={styles.responsive}>
      <div className={styles.divColor}>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} md={6} justify="center">
            <Typography variant="h5" className={styles.questions}>
              あと{5 - value.length}問
            </Typography>
            <LinearProgress
              className={styles.progressBar}
              color="primary"
              variant="determinate"
              value={(value.length / selections.length) * 100}
            />
            <Typography variant="h4" className={styles.questions}>
              {selections[value.length].question}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={4}
          className={styles.wholeDesign}
        >
          <Grid item xs={12} md={5}>
            <ComplexButton
              onClick={() => update(0)}
              title={selections[value.length].selection[0].id}
              imageSrc={selections[value.length].selection[0].img}
              width="100%"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={2}
            spacing={4}
            justify="space-around"
          >
            {value.length > 0 ? (
              <Grid item>
                <Button
                  className={styles.button}
                  variant="contained"
                  onClick={prev}
                >
                  前へ戻る
                </Button>
              </Grid>
            ) : (
              " "
            )}
            <Grid item>
              <Button
                className={styles.button}
                variant="contained"
                onClick={() => update(null)}
                disabled={
                  selections.length - 1 === value.length &&
                  value.every((v) => v === null)
                }
              >
                スキップ
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <ComplexButton
              onClick={() => update(1)}
              title={selections[value.length].selection[1].id}
              imageSrc={selections[value.length].selection[1].img}
              width="100%"
            />
          </Grid>
        </Grid>
        <Grid container className={styles.backButton} justify="center">
          <Button variant="contained">
            <Typography component="h2">
              <Link to="/">
                <WrapTextIcon />
                Back
              </Link>
            </Typography>
          </Button>
        </Grid>
        <Backdrop open={loading} className={styles.overlay}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Grid>
  );
};

export default TwoSelection;
